/********************  Global Css  ********************/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
/* *{
    font-family: 'poppins';
} */
*{
    font-family: 'Poppins', sans-serif;
  }
*::-webkit-scrollbar {
    width: 6px !important;
}

*::-webkit-scrollbar-track {
    background: #e9e1d499 !important;
    border-radius: 5px !important;
}

*::-webkit-scrollbar-thumb {
    background-color: #fbbb1a8f !important;
    border-radius: 5px !important;
    border: 1px solid #fbbb1a8f !important;
}

html {
    overflow-y: scroll;
    scrollbar-color: #fbbb1a8f #e9e1d499;
    scrollbar-width: thin;
}

.font-size-xs {
    font-size: 10px !important;
}

.font-size-xs {
    font-size: 10px !important;
}

.font-size-sm {
    font-size: 13px !important;
}

.font-size-md {
    font-size: 16px !important;
}

.font-size-lg {
    font-size: 18px !important;
}

.font-size-xl {
    font-size: 20px !important;
}

.font-size-xxl {
    font-size: 24px !important;
}

.primary-label {
    font-size: 16px !important;
    font-weight: 500;
}

.font-poppins {
    font-family: 'poppins';
}

.font-weight-1 {
    font-weight: 300;
}

.font-weight-2 {
    font-weight: 500 !important;
}

.font-weight-3 {
    font-weight: 700 !important;
}

.line-height-1 {
    line-height: 0.5;
}

.cursor-pointer {
    cursor: pointer !important;
}

.primary-heading {
    font-size: 22px !important;
    font-weight: 500 !important;
}

.secondary-heading {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.tertiary-heading {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.opacity-initials {
    opacity: initial !important;
}

.bg-gray {
    background-color: #f8f8f8 !important;
}

.theme-color {
    color: #fbbb1a;
}

.bg-theme-color {
    background-color: #fbbb1a;
}

.custom-avatar {
    border-radius: 50% !important;
    padding: 10px !important;
    background-color: rgb(251, 187, 26) !important;
    width: 42px !important;
    color: rgb(255, 255, 255) !important;
    text-align: center !important;
}

.custom-cross-btn {
    position: absolute;
    margin-left: -15px;
    margin-top: -10px;
    color: #fff;
    background: #fbbb1a;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    width: 25px;
    height: 25px;
}

.spinner {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-image-gallery {
    width: 208px;
    height: 100px;
    object-fit: contain;
}

.custom-image {
    width: 100%;
    height: 137px;
    object-fit: contain;
}

.form-control {
    font-size: 14px !important;
}

.w-15 {
    width: 15% !important;
}

/******************* Right bar css ****************/
@media (max-width: 767.98px) {
    .profile-media .media-body {
        display: block !important;
    }
}

@media (max-width: 575.98px) {
    .page-wrapper .profile-nav {
        display: block !important;
    }
}


/************* header css *********************/
.header-main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}


.plus-btn-div {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    margin-top: -20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fbbb1a;
    color: #fff;
    margin-right: -20px
}

.select-daterange-div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.searchDiv {
    margin: 10px;
}

@media only screen and (max-width: 720px) {
    .select-daterange-div {
        flex-direction: column;
    }

    .searchDiv {
        width: 70% !important;
        margin: 10px 0 !important;
    }

    .selectDiv {
        margin-bottom: 10px;
    }

    .DPDiv {
        width: 70% !important;
        margin-left: 0 !important;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .PSDiv {
        width: 70% !important;
        margin-left: 0 !important;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .PS {
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 10px;
    }
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #fbbb1a;
}

.rs-calendar-table-cell-in-range:before {
    background-color: #fff8dc;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: #fff8dc;
    color: black;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #fbbb1a
}

.rs-btn-primary {
    background-color: #fbbb1a;
}

.rs-btn-primary:not(:disabled) {
    background-color: #fbbb1a;
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
    background-color: #fbbb1a;
}

.rs-btn-link {
    color: #fbbb1a;
}

.rs-btn-link:hover {
    color: #fbbb1a;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #1f160d;
}

/******************* Drawing Map ******************/
.popover {
    position: absolute;
    z-index: 9999999;
    float: left;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.swatch {
    margin-top: 5px;
    padding: 6px;
    background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .2);
}

.swatch-container {
    display: inline-block;
    cursor: pointer;
    width: 100%;
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.swatch-popover {
    position: absolute;
    z-index: 2;
}

.swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.rs-drawer-body {
    padding: 16px 25px !important;
}

.zoneDrawer .rs-drawer-header button {
    display: none;
}

.zoneDrawer .rs-drawer-header {
    padding-left: 40px;
}

.colorPickerBar {
    position: absolute;
    top: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.colorPickerBar .box {
    background-color: rgba(255, 255, 255, 0.8);
    margin: 0 10px;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gmnoprint>div>button {
    top: 10px;
    height: 40px !important;
}

.user-profile .hovercard .user-image .avatar {
    margin-top: -70px;
    margin-left: 30px;
}

.rs-picker-menu {
    z-index: 100000;
}

.slide {
    transform: translateX(10px);
}

@media only screen and (max-width: 690px) {
    .col-9 {
        flex: 0 0 100%;
        border: 1px solid blue;
        max-width: 100% !important;
        width: 100% !important;
    }

    #drawing-manager-example {
        border: 1px solid red;
        width: 100% !important;
        max-width: 100% !important;
        height: 600px !important;
    }

    .gmnoprint {
        top: 40px !important;
    }
}

.infoCard-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

.side-drawer {
    transform: translateX(0);
    transition: transform 0.3s ease-in;
}

.side-drawer.open {
    transform: translateX(3%);
    transition: transform 0.3s ease-out;
}

.checkbox label::before {
    border: 1px solid #ced4da;
}

.nav-link.active {
    background-color: "#FBBB1A" !important;
}

/********************  Common  ********************/


/********************  Settings  ********************/
.nav-pills {
    cursor: pointer;
}

.nav-link {
    color: black;
}

.nav-link:hover {
    color: black;
}

.custom-scroll {
    overflow-y: scroll !important;
}

/********************  SignIn  ********************/
@media only screen and (max-width: 690px) {
    .img-fluid {
        max-width: 40% !important;
    }

    .login-card .login-main.login-tab {
        margin-top: 30px !important;
    }
}

/********************  DashBoard  ********************/

.apexcharts-menu-icon {
    display: none;
}

/********************  Map Control Buttons  ********************/
@media only screen and (max-width: 690px) {
    .labelBtn {
        top: 180px !important;
    }

    .siteCondition {
        display: block !important;
    }
}

/********************  Issues  ********************/
.map-label {
    background-color: white;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    transform: translate(21.8px, 15px);
    padding-top: 2px;
}

.project-box .badge {
    position: relative;
    left: 0;
    top: 0;
    margin-right: auto;
}

.img-20 {
    width: 40px !important;
}

.pac-container {
    z-index: 9999999999999
}

@media (min-width: 1550px) {
    .col-xxl-3 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
}

@media only screen and (max-width: 690px) {
    .ada {
        margin-left: 18px;
    }
}

/******************** Report Site Map  ********************/
.siteCondition {
    display: flex;
}

@media (min-width: 300px) and (max-width: 990px) {
    #portfolio-report-tab {
        position: unset !important;
        width: inherit !important;
        max-width: 100% !important;
    }

    #breadcrumbs-span {
        position: unset !important;
    }
}

.delete-file-icon-1:hover+.delete-icon-1 {
    display: block;
    top: -10px;
    right: 7px;
    position: absolute;
    font-size: 27px;
}

/* .delete-icon-1 {
    display: none;
} */

.delete-icon-1 {
    display: block;
    top: -10px;
    right: 7px;
    position: absolute;
    font-size: 27px;
    cursor: pointer;
}

/* .delete-file-icon-1:hover + .delete-icon-1  {
    display: block;
    top: -10px;
    right: 7px;
    position: absolute;
    font-size: 27px;
}

/* .delete-icon-1 {
    display: none;
} */

.delete-icon-1 {
    display: block;
    top: -10px;
    right: 7px;
    position: absolute;
    font-size: 27px;
    cursor: pointer;
}

/* .delete-file-icon-1:hover + .delete-icon-1  {
    display: block;
    top: -10px;
    right: 7px;
    position: absolute;
    font-size: 27px;
}

.delete-icon-1 {
    display: none;
}

.delete-icon-1:hover {
    display: block;
    top: -10px;
    right: 7px;
    position: absolute;
    font-size: 27px;
    cursor: pointer;
} */

.delete-file-icon:hover+.delete-icon {
    display: block;
    top: -100px;
    position: relative;
    left: 156px;
    width: 0px;
    height: 0px;
    font-size: 27px;
}

.delete-icon {
    display: none;
}

.delete-icon:hover {
    display: block;
    top: -100px;
    position: relative;
    left: 156px;
    width: 0px;
    height: 0px;
    font-size: 27px;
    cursor: pointer;
}

#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    width: 100%;
    padding: 40px 0px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #FBBB1A;
    background-color: #ededed;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

/************************* Toggle Map *********************/

.switch-button {
    background: red;
    border-radius: 30px;
    overflow: hidden;
    width: 240px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    color: #155FFF;
    padding-right: 120px;
    position: relative;
}

.switch-button ::before {
    content: "Video";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
}

.switch-button:checked {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}

.switch-button-label {
    position: relative;
    padding: 15px 0;
    display: block;
    pointer-events: none;
}

.switch-button-label::before {
    content: "";
    background: pink;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
}

.switch-button-label+ :checked {
    transform: translateX(120px);
    transition: transform 300ms linear;
}

.inp-dark-border::after {
    border: 1px solid #b5b5b5;
}

.per-sub .inp-dark-border::after {
    width: 1rem !important;
    height: 1rem !important;
}

.per-sub .inp-dark-border::before {
    width: 0.45rem;
    height: 0.200rem;
}

.per-sub .inp-dark-border {
    margin: 0 0.4rem 0 0;
}

.g-img-checkbox {
    top: 5px;
    right: 5px;
}

.g-img-checkbox:after {
    border-radius: 5px;
}

.gm-ui-hover-effect>span {
    width: 20px !important;
    height: 20px !important;
    margin: 0px !important;
}

.gm-ui-hover-effect {
    top: 1px !important;
    right: -8px !important;
}

.gm-style-iw {
    padding-top: 0px !important;
    padding-left: 0px !important;
}

.multi-filter .basic-single {
    width: 250px;
}

.multi-filter .divider {
    width: 1px;
    height: 38px;
    background-color: #ccc;
    margin: 0 38px;
}

.c-hover:hover {
    opacity: 0.5;
    transition: 0.3s ease-in-out;
}

.media-group-btn .rs-checkbox-checker {
    padding: 14px 10px 0px 10px;
    min-height: auto;
}

.media-group-btn .rs-checkbox-wrapper {
    left: 2px;
    top: 5px;
}

.rs-toggle-checked .rs-toggle-presentation {
    background-color: rgb(251, 187, 26) !important;
}

.rs-modal-wrapper {
    z-index: 1055 !important;
}

.media-dropdown .rs-dropdown-item.rs-dropdown-item-focus,
.rs-dropdown-item:focus-visible {
    color: rgb(251, 187, 26) !important;
    background-color: rgba(251, 187, 26, 0.137) !important;
}

.media-gallery .zone-title {
    position: absolute;
    bottom: 9px;
    left: 1px;
    width: 95%;
    color: #000;
    text-align: center;
    z-index: 1;
}

.info-box-input:hover {
    background-color: rgba(251, 187, 26, 0.466);
    cursor: pointer;
    transition: background-color .8s;
}

.rs-dropdown-item.rs-dropdown-item-active,
.rs-dropdown-item.rs-dropdown-item-active:hover,
.rs-dropdown-item.rs-dropdown-item-active:focus {
    color: rgb(251, 187, 26) !important;
    background-color: #f2faff;
}

.rs-dropdown-item:focus-visible,
.rs-dropdown-item.rs-dropdown-item-focus {
    background-color: rgb(251, 187, 26, 0.2) !important;
    color: rgb(251, 187, 26) !important;
}

.rs-btn-primary:focus,
.rs-btn-primary:hover {
    background-color: rgb(251, 187, 26, 0.6) !important;
    color: #fff;
}

@media (max-width: 767px) {
    .portfolio-filter-btn {
        padding: 3px 3px;
    }

    .rs-drawer {
        width: 100% !important;
    }

    .icon-btn {
        width: 18px;
        height: 18px;
    }

    .plus-btn-div {
        margin-right: -11px;
    }

    .custom-image-gallery {
        max-width: 100% !important;
    }

    .static-top-widget .portfolio-count {
        font-size: 70px;
    }
}

@media (min-width: 768px) and (max-width: 1399px) {
    .portfolio-filter-btn {
        padding: 4px 15px;
        font-size: 12px;
    }

    .static-top-widget .portfolio-count {
        font-size: 70px;
    }
}

@media (min-width: 1400px) {
    .portfolio-filter-btn {
        padding: 5px 20px;
    }

    .static-top-widget .portfolio-count {
        font-size: 90px;
    }
}

.modal-title {
    font-size: 16px;
    font-weight: 500;
}

.custom-file-input-1 {
    border: 1px solid #e4e4e4;
    width: 100%;
    border-radius: 4px;
}

.btn-close {
    width: 20px;
    height: 20px;
    position: relative;
    background-color: transparent;
}

.btn-close::before,
.btn-close::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #979797;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.btn-close::before {
    transform: rotate(45deg);
}

.btn-close::after {
    transform: rotate(-45deg);
}

.rs-picker-toggle-value {
    color: gray !important;
}

.accordion-wrapper {
    padding: 5px !important;
    padding-bottom: 0 !important;
}
.accordion-wrapper-Outside{
    padding: 20px !important;
}

.accordion-header {
    width: 100% !important;
    background-color: #f3f3f3 !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.accordion-button {
    border-top-left-radius: 8px !important;
}

.accordion-item {
    border-radius: 8px !important;
}

.accordion-body {
    background-color: #fbbb1a24 !important;
    padding: 10px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

/* .scrolling{
    overflow-y: scroll !important;
    max-height: 250px !important; 
    width:100% !important;
} */
.scrolling-container {
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    padding-right: 20px;
}

.scrolling-container p {
    margin: 0;
    /* Remove default margin to prevent extra space */
}

.custom-uploader {
    max-height: 300px;
    overflow-y: auto;
}

.custom-uploader-btn {
    background-color: rgb(251, 187, 26);
}

.custom-uploader .uploadPictureContainer img.uploadPicture {
    height: 85px !important;
    width: 10px !important;
}

.custom-uploader .uploadPictureContainer {
    width: 19% !important;
}

.create-work-order-drawer .rs-drawer-header .rs-drawer-header-close {
    top: 8px
}

.icon-btn-green {
    background-color: #b4e3b4 !important;
    color: black !important;
}

.icon-btn-green svg {
    background-color: #00af052b !important;
}

.icon-btn-red {
    background-color: #e3b4b4 !important;
    color: black !important;
}

.icon-btn-red svg {
    background-color: #af00002b !important;
}

.icon-btn-primary {
    background-color: #ffb6004d !important;
    color: black !important;
}

.icon-btn-primary svg {
    background-color: #ffb60094 !important;
}

@media screen and (max-width: 974px) {
    .arrow-collapse {
        display: none;
    }

    .nav-items {
        width: 100%;
    }

    .arrow-icon {
        display: none;
    }
}

@media screen and (min-width: 975px) {
    .arrow-icon {
        float: inline-start;
        transition: transform 0.5s ease-in-out;
    }

    .transitionaleffect {
        transition: all 0.5s ease;
    }

    .arrow-collapse {
        cursor: pointer;
        float: inline-end;
        transition: transform 0.5s ease-in-out;
    }

    .arrow-collapse-collapsed {
        transform: rotate(180deg);
        float: inline-start;
        transition: transform 0.5s ease-in-out;
    }

    .nav-items {
        width: 100%;
        transition: transform 0.5s;
    }

    .nav-items.collapsed .nav-title {
        display: none;
    }
}

.avtartop {
    margin-top: 30px;
}

.custom-work-order-image-uploader button:hover {
    background-color: #ffb7000a !important;
    transition: none !important;
    color: #FBBB1A !important;
}

.custom-image-upload-item :hover {
    background-color: #ffb7000a !important;
    color: #FBBB1A !important;
    transition: none !important;
}

.custom-work-order-image-uploader :hover {
    color: #FBBB1A !important;
    background-color: #ffb7000a !important;
    transition: none !important;
}

.custom-work-order-image-uploader {
    background-color: #ffb7000a !important;
}

.custom-image-upload-item rs-dropdown-item :hover {
    background-color: #ffb7000a !important;
}

.custom-image-upload-item rs-dropdown-item {
    background-color: #ffb7000a !important;
}

.custom-btn {
    color: #000 !important;
    padding: 0 !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
}

.custom-btn :hover {
    background-color: #ffb7000a !important;
    color: #FBBB1A !important;
    transition: none !important;
}

.custom-image-upload-item custom-btn :hover {
    background-color: #ffb7000a !important;
    color: #FBBB1A !important;

}

.portfolio-ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
}

.portfolio-ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: capitalize;
    text-align: center;
    padding: 1px;
}

.portfolio-ribbon-top-left span {
    right: -44px;
    top: 25px;
    transform: rotate(-45deg);
}

.portfolio-ribbon-danger {
    background-color: red;
}

.portfolio-ribbon-success {
    background-color: green;
}

.portfolio-circle {
    overflow: hidden;
    margin: auto;
    display: flex;
    margin-top: 20px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 13px 4px rgb(0 0 0 / 15%);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    border: 1px solid #faba1a;
    background: #fff;
}

.portfolio-circle img {
    width: 100%;
    /* Ensure the image takes 100% width of the container */
    height: 100%;
    /* Ensure the image takes 100% height of the container */
    object-fit: contain;
    /* Set the object-fit property to cover */
}

.fileContainer .deleteImage {
    background: #faba1a !important;
}

.custom-image-upload-item custom-btn :hover {
    background-color: #ffb7000a !important;
    color: #FBBB1A !important;
}

.tab-hover:hover {
    background-color: #fbbb1a2e !important;
    box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 1%);
}

.nav-item:hover {
    border-radius: 7px;
}

.password-input-group {
    display: flex;
    flex-direction: column;
}

.password-input-container {
    position: relative;
}

.password-toggle {
    position: absolute;
    right: 10px;
    /* Adjust the right position as needed */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.logo-container {
    max-width: 100%;
    text-align: center;
}

.logoimg {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .logoimg {
        max-width: 200px;
    }

}

.rdt_TableRow {
    justify-content: space-between;
}

.rdt_TableHeadRow {
    justify-content: space-between;
}

.signing {
    position: relative;

}


.select__menu {
    z-index: 999999999999 !important;
}

.show-hide {
    position: absolute;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    span {
        cursor: pointer;
        font-size: 15px;
        color: var(--theme-default);
      }
    
      .show:before {
        content: "show";
      }
    
      .hide:before {
        content: "hide";
      }
}
.fileContainer .errorMessage {
    display: none;
}

.editorstyling {
    overflow-x: auto;
    border: #eee 2px solid;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.editor-workorder {
    height: 225px;
    .toolbar-class {
        position: absolute;
        right: 17px;
        left: 17px;
        z-index: 9;
    }
    
}
.editor-proposal {
    height: 225px;
    .toolbar-class {
        position: absolute;
        right: 17px;
        left: 17px;
        z-index: 9;
    }
}
.public-DraftStyleDefault-ol {
    display: unset !important;
  }
.editor-add-line-item-2{
    height: 300px;
    width: 99%;
    .toolbar-class {
        position: absolute;
        right: 17px;
        left: 26px;
        z-index: 1;
    }
    .DraftEditor-root{
        z-index: 0;
    }

}
.editor-scope-description{
    height: 300px;
    width: 100%;
    .toolbar-class {
        position: absolute;
        right: 16px;
        left: 18px;
        z-index: 1;
    }
    .DraftEditor-root{
        z-index: 0;
    }
}
.lineItem-select{
    z-index: 7;
}

.lineItem-select-zIndex-6{
    z-index: 6;
}
.lineItem-type-select{
    z-index: 1000;
}

.lineItem-type-select-zIndex-2000{
    z-index: 999;
}
tr.dragging {
    opacity: 0.5; /* Optional: make the dragged row semi-transparent */
}


.editor-terms {
    height: 500px;
    .toolbar-class {
        position: absolute;
        right: 46px;
        z-index: 7;
        left: 25px;
    }
}
.editor-AdditionalInfo {
    height: 350px;
    .toolbar-class {
        position: absolute;
        right: 26px;
        left: 26px;
        z-index: 7;
    }
}
.toolbar-class {
    display: flex !important;
    justify-content: space-between !important;
    border: #22262b 2px solid;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}
.rdw-wrapper .rdw-editor-main {
    padding: 35px 10px 18px 10px !important;   
  }
  .DraftEditor-root {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #1a1919 !important;
  }

.rdw-option-wrapper {
    border: #53779b !important;
    border-radius: 5px !important;
    height: max-content !important;
    width: max-content !important;
}
.rdw-option-wrapper:hover {
    background-color: #ffb60094 !important;
    transition: background-color 0.3s ease;
}
.rdw-option-active{
    background-color: #ffb60094 !important;
    transition: background-color 0.3s ease;
}
.item-group {
    gap: 15px;
}

.rdw-editor-main {
    padding: 35px 10px 18px 10px !important;
}

.editor-add-line-item-2 .rdw-editor-wrapper .rdw-editor-main {
    padding: 50px 10px 18px 10px !important;

    .public-DraftStyleDefault-block {
        margin: 0em 0 !important;
    }
}

.hover-effect:hover {
    color: #fa9b2f;
    cursor: pointer;
}

.gm-ui-hover-effect {
    top: -4px !important;
    right: -3px !important;
}

/* Styles for large screens */
.thumbnail {
    width: 50px;
    height: 40px;
    border: 2px solid #ddd;
    transition: transform 0.3s ease-in-out;
}

.enlarged-image {
    z-index: 1000;
    position: fixed;
    height: 400px;
    width: 400px;
    border-radius: 8px;
    object-fit: contain;
    border: 1px solid #000;
    transition: transform 0.3s ease;
    object-fit: fill;
}

.enlarged-image-container {
    position: fixed;
    z-index: 100 !important;
}

.thumbnail-column {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnail:hover {
    transform: scale(1.1);
}

.navigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    font-size: 35px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    z-index: 10000;
    background-color: #fa9b2f;
    border-radius: 50%;
    height: 35px;
    min-width: 35px;
    align-items: center;
    padding: 10px;
    border: 1px solid #fa9b2f;
    justify-content: center;
    opacity: 0.8;
}

.navigation:hover {
    background-color: #000;
    color: #ccc;
    border: none;
}

.nav-pre {
    position: absolute;
    top: 187px;
    left: 358px;
}

.nav-next {
    position: absolute;
    top: 187px;
    right: -40px;
}

.rowg {
    display: flex;
    flex-wrap: wrap;
    margin-right: 1px;
    margin-left: 0px;
}

@media (max-width: 767px),
(max-width: 1023px) {
    .enlarged-image {
        height: 200px;
        width: 200px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .enlarged-image-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .nav-pre {
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .nav-next {
        position: absolute;
        top: 0px;
        left: -95px;
    }
}

.custom-font-style {
    font-weight: 700;
    color: #2b2b2b;
    font-family: 'poppins';
    display: flex;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    margin-bottom: 5px;
}
.rating-font {
    display: flex;
     justify-content: center;
     align-items: center;
    gap: 2px;
    font-weight: bold;
}
.dollar-sign-addon {
    height: 28px;
    padding: 3px;
    font-size: 12px;
    color: #2b2b2b;
}
.input-group-addon {
    display: inline-flex;
    align-items: center;
}
.rdt-scroll > div:nth-child(2) {
    overflow-x: unset;
    overflow-y: clip;
}

.lineitems-box{
overflow-x: auto;
overflow-y: auto;
max-height:400px; 
}
.rs-divider-horizontal{
margin-top: 10px !important;
margin-bottom: 10px !important;
}
.lineitems-box-wo{
    overflow-x: auto;
    overflow-y: auto;
    max-height:250px; 
    }
.rdw-colorpicker-modal{
    position: absolute;
    top: 35px;
    right: 15px;
    display: flex;
    flex-direction: column;
    width: 280px !important;
    height: 250px !important;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}
.rdw-colorpicker-modal-options{
    overflow: hidden !important;
}
  .rdw-right-aligned-block * {
    text-align: right !important;
  }
  .rdw-left-aligned-block * {
    text-align: left !important;
  }
  .rdw-center-aligned-block * {
    text-align: center !important;
  }
  .rdw-justify-aligned-block * {
    text-align: justify !important;
  }

  .wo-row-draggable{
    cursor: move;
    transition: background-color 0.3s ease;
  }
  
  .wo-row-draggable:hover{
    cursor: move;
    opacity: 0.8;
  }
  

  .custom-total-cell {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    min-width: 200px;
    max-width: 200px;
    font-weight: 700 !important;
}
.custom-total-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: border-box;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
    background-color: #FFFFFF;
    min-height: 48px;
    border: solid 2px #f2f2f5;
    border-top: none;
}

.tabulator-row.tabulator-group {
    box-sizing: border-box;
    padding: 5px 5px 5px 10px;
    background: transparent !important;
    font-weight: 700;
    min-width: 100%;
    border: none !important;
    border-bottom: 1px solid #ced4da !important;
}


.tabulator .tabulator-header {
    color: black !important;  
    white-space: nowrap;
    height: 40px;
    overflow: hidden;
    background-color: #f0f0f0 !important;
    font-weight: 400;
    border: none !important;
}


.tabulator {
    background-color: transparent !important;
    border: none !important;
}

.tabulator-row.tabulator-row-even {
    background-color: transparent !important;
}


.tabulator-arrow , .tabulator-group-toggle{
  display:none !important;
}
.numeric-input {
    width: 100%;
    outline: none;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 5px;
}

.tabulator-col {
    border: none !important;
    background: none !important;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
}

.tabulator-col-title {
    margin: 3px;
}
.tabulator-row .tabulator-cell {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 4px;
    border-right: none !important;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.drag-highlight {
    background-color: #F7D174;
  }
  